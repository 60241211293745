@import '../../styles/palette';

.select {
  width: 100%;
  padding: 9px 12px;
  border-radius: 8px;
  border: 2px solid rgba($color-primary, 0.25);
  background: #fff;
  height: 44px;
  font-family: $font-family-main;
  color: $color-text;
  font-size: 16px;
  font-weight: 400;

  &:focus {
    outline: none;
  }
  &:disabled {
    background: #e9ecef;
  }
}
