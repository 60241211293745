@import '../../../../styles/palette';

.wrapper {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  & p {
    font-family: $font-family-main;
    font-weight: 700;
    font-size: 18px;
    color: $color-text;
  }
}

.selectBox {
  display: flex;
  flex-direction: column;
  gap: 7px;
  color: $color-text;
}

.tBankButton {
  width: 100%;
  height: 44px;
  border-radius: 6px;
  background-color: #ffdd2d;
  border: none;
  color: #333333;
  font-size: 16px;
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

.RRbutton {
  width: 100%;
  height: 44px;
  border-radius: 6px;
  background-color: #eae9f1;
  border: 1.5px solid $color-primary;
  color: $color-primary;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}
